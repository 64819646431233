<template>
	<div class="fragment">
		<div class="content-top-line content-top-line--with-bottom-line">
			<div class="content-tabs content-tabs--separator"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
					 'loading-tabs': $store.getters.getCusPaymentsLoading === true,}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_other', 'cuspayments_balanceOutput'])"></span>

				<div class="content-tabs__item"
						 v-if="userTabs.paypal.permissionVisible"
						 v-bind:class="{active: userTabs.paypal.active}"
						 @click="changeTab('paypal')"
				>
          {{ userTabs.paypal.name }}
				</div>
				<div class="content-tabs__item"
						 v-if="userTabs.authorize.permissionVisible"
						 v-bind:class="{active: userTabs.authorize.active}"
						 @click="changeTab('authorize')"
				>
          {{ userTabs.authorize.name }}
				</div>
				<div class="content-tabs__item"
						 v-if="userTabs.braintree.permissionVisible"
						 v-bind:class="{active: userTabs.braintree.active}"
						 @click="changeTab('braintree')"
				>
          {{ userTabs.braintree.name }}
				</div>
				<div class="content-tabs__item "
             v-if="userTabs.other.permissionVisible && !isAdmin"
						 v-bind:class="{active: userTabs.other.active}"
						 @click="changeTab('other')"
				>
          {{ userTabs.other.name }}
				</div>
				<div class="content-tabs__item "
						 v-if="userTabs.balanceOutput.permissionVisible"
						 v-bind:class="{active: userTabs.balanceOutput.active}"
						 @click="changeTab('balanceOutput')"
				>
          {{ userTabs.balanceOutput.name }}
				</div>
<!--        (isAdmin || isUserHeartyManufacturer)-->
				<div class="content-tabs__item "
						 v-if="userTabs.heartysan && userTabs.heartysan.permissionVisible && isUserHeartyManufacturer"
						 v-bind:class="{active: userTabs.heartysan && userTabs.heartysan.active}"
						 @click="changeTab('heartysan')"
				>
          {{ userTabs.heartysan.name }}
				</div>
			</div>

			<div class="content-top-line-wrap">
				<ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
			</div>
		</div>

		<div class="light-bg-block">
			<div class="btn-left-block">

			</div>
			<div class="btn-right-block">
        <div class="content-top-light-bg-block ml-2" style="position: relative;"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(), 'admin-btn-style' : isAdmin}">
        <span class="admin-edit" @click="editTranslate([
          'cuspayments_importTrackById',
        ])"></span>
          <TableHeadButton
              :value="$t('cuspayments_importTrackById.localization_value.value')"
              :ico="'import'"
              @click.native="changeImportTrackPopup(true)"
          />
          <div class="likeTooltipBtn">
            <TableHeadButton
                :value="''"
                :ico="'question'"
                class="likeTooltipBtn__item"
                @click.native="downloadExample"
            />
          </div>
        </div>
        <div v-if="isAdmin"
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['cusPayments_ImportTransaction'])"></span>
          <MainButton class="btn-fit-content brown mr-3"
                      :value="$t('cuspayments_importTransaction.localization_value.value')"
                      v-if="!userTabs.unassigned.active"
                      @click.native="changeImportTransactionPopup(true)"
          />
        </div>
        <!--<span>-->
          <!--<MainButton class="btn-fit-content"-->
											<!--:value="'View Refunds'"-->
					<!--/>-->
        <!--</span>-->
			</div>
		</div>

    <ImportTransactionPopup
        v-if="isModalImportTransactionPopup"
        @close="changeImportTransactionPopup(false)"
    />

    <ImportTrackByIdPopup
        v-if="isModalImportTrackPopup"
        @close="changeImportTrackPopup(false)"
    />

	</div>
</template>

<script>
	import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import ImportTransactionPopup from "../../../../popups/ImportTransactionPopup/ImportTransactionPopup";
  import TableHeadButton from "../../../../../../coreComponents/TableComponents/TableHeadButton/TableHeadButton";
  import ImportTrackByIdPopup from "../../../../popups/ImportTrackByIdPopup/ImportTrackByIdPopup";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";

	export default {
		name: "CusPaymentsHead",
		components: {
      MainButton,
      ImportTrackByIdPopup,
			ToggleFilterButton,
      ImportTransactionPopup,
      TableHeadButton,
		},

		props: [
			'showFilter',
			'activeTab',
			'userTabs',
			'countFilterParams',
		],

		watch: {
			activeTab(newVal) {
				this.tab = newVal
			}
		},

		data(){
			return{
				tab: this.activeTab,

        searchField1: '',
        searchField2: '',
        searchField3: '',
        searchField4: '',


        isShowSearchEngineBlock: false,
        isModalImportTransactionPopup: false,
        isModalImportTrackPopup: false,
			}
		},

		methods: {
      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

			changeTab(id) {
				this.$emit('changeTab', id)
			},

      changeImportTransactionPopup(val){
        this.isModalImportTransactionPopup = val
      },

      changeImportTrackPopup(val){
        this.isModalImportTrackPopup = val
      },

      downloadExample() {
        this.$store.dispatch('getImportExampleXLSXTransactionTrackById').then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            const blob = new Blob([response.data], {type: 'application/xlsx'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'Transaction_track_import_example.xlsx'
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },
		}
	}
</script>

<style scoped>
.likeTooltipBtn{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-20px, -13px);
}

.likeTooltipBtn__item{
  padding: 0;
  width: 20px;
  margin-top: -18px;
  margin-left: 4px;
}

@media (max-width: 1220px){
  .likeTooltipBtn{
    transform: translate(-8px, -13px);
  }

}

@media (max-width: 1220px){
  .print-btn__btn {
    padding-right: 16px;
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .likeTooltipBtn {
    /*position: absolute;*/
    /*top: -12px;*/
    /*right: -10px;*/
    /*transform: none;*/
  }
}

	@media(max-width: 1600px) {
		.content-tabs__ico img{
			width: auto;
			height: 15px;
		}
	}

	.content-top-line .admin-edit{
		top: 0 !important;
	}

  @media (max-width: 420px) {
    .admin-btn-style {
      position: relative;
      max-width: 122px;
      padding-top: 4px;
    }
  }

</style>
