<template>
  <CusPaymentsEditAdmin/>
</template>

<script>
  import CusPaymentsEditAdmin from "./CusPaymentsEditAdmin/CusPaymentsEditAdmin";

  export default {
    name: "CusPaymentsEdit",
    components: {
      CusPaymentsEditAdmin,
    },
  }
</script>

<style scoped>

</style>