var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal',{staticClass:"custom-popup medium-size",on:{"close":function($event){return _vm.$emit('close')}}},[_c('template',{slot:"header"},[_c('span',{class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['cuspayments_importTransaction',])}}}),_vm._v(" "+_vm._s(_vm.$t('cuspayments_importTransaction.localization_value.value'))+" ")])]),_c('template',{slot:"body"},[_c('div',{staticClass:"import-transaction__content",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
      'cuspayments_toImportTransaction',
      'importO_transactionNumber',
      'importO_transactionNumber',
      'common_cancel',
      'common_send',
      ])}}}),_c('div',{staticClass:"custom-popup__txt size-18px"},[_vm._v(" "+_vm._s(_vm.$t('cuspayments_toImportTransaction.localization_value.value'))+" ")]),_c('div',{staticClass:"import-transaction__row custom-row"},[_c('div',{staticClass:"import-transaction__col custom-col mb-3"},[_c('RadioDefault',{attrs:{"label":_vm.$t('importO_transactionNumber.localization_value.value'),"name":'transNumber',"value":true},nativeOn:{"change":function($event){return _vm.changeRadio(0)}}})],1),(_vm.radioValue === 0)?_c('div',{staticClass:"import-transaction__col custom-col custom-col--50 custom-col--sm-100"},[_c('DefaultInput',{staticClass:"ml-4",attrs:{"label":_vm.$t('importO_transactionNumber.localization_value.value'),"type":'text'},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1):_vm._e(),(_vm.radioValue === 1)?_c('div',{staticClass:"import-transaction__col custom-col"},[_c('DropZone',{staticClass:"drop-zone-bg",attrs:{"parentFiles":_vm.files,"maxSize":'5'},on:{"changeImg":_vm.changeImg}})],1):_vm._e()])])]),_c('template',{slot:"footer"},[_c('div',{staticClass:"import-transaction__btn d-flex align-items-center flex-wrap"},[_c('span',{staticClass:"site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.$t('common_cancel.localization_value.value'))+" ")]),_c('MainButton',{staticClass:"import-transaction__btn-i",attrs:{"value":_vm.$t('common_send.localization_value.value')},nativeOn:{"click":function($event){return _vm.importTransaction.apply(null, arguments)}}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }