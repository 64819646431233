<template>
  <div class="per-page-block">
    <DefaultSelect
        class="per-page-select"
        :options="perPageVarsArray"
        :optionsLabel="'value'"
        :clearable="false"
        :searchable="false"
        :selected="currentPerPage"
        @change="changePerPage"
    />
  </div>
</template>

<script>
import DefaultSelect from "../../UI/selectiones/DefaultSelect/DefaultSelect";
import {perPageVarsArray} from "../../../staticData/staticVariables";
export default {
  name: "PerPageSelect",
  components: {DefaultSelect},
  props: {
    currentPerPage: {
      type: Number,
      default: 25,
    },
    cookieName: String,
  },
  data() {
    return {
      perPageVarsArray: perPageVarsArray,
    }
  },
  methods: {
    changePerPage(val) {
      let expiresDate = new Date()
      expiresDate.setMonth(expiresDate.getMonth() + 1)
      this.setCookie(this.cookieName, val.value, + expiresDate)
      this.$emit('changePerPage', val.value)
    }
  }
}
</script>

<style scoped lang="scss">
/deep/ .per-page-select .vs__dropdown-toggle {
  min-height: 35px !important;
}
/deep/ .per-page-select .vs__selected {
  top: 5px !important;
}

.per-page-block {
  max-width: 75px;
  width: 100%;
  flex-shrink: 0;
  margin-right: 15px;
}

.vs__dropdown-menu {
  min-width: 75px;
}

@media (max-width: 420px) {
  .per-page-block {
    margin-left: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }
}
</style>