import { render, staticRenderFns } from "./PerPageSelect.vue?vue&type=template&id=05ba110a&scoped=true"
import script from "./PerPageSelect.vue?vue&type=script&lang=js"
export * from "./PerPageSelect.vue?vue&type=script&lang=js"
import style0 from "./PerPageSelect.vue?vue&type=style&index=0&id=05ba110a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05ba110a",
  null
  
)

export default component.exports