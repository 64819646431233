<template>
	<FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
		<template slot="body">
			<div class="table-filter__item"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_IdOrder'])"></span>

				<DefaultInput
								:label="$t('cuspayments_IdOrder.localization_value.value')"
								:type="'text'"
								v-model="order"
				/>
			</div>
      <div class="table-filter__item" v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :functionSearch="functionSearch"
            @change="changeUsersFilter"
            :selected="userName"
            :userEmail="true"
            :typeSelect="'users'"
        />
      </div>
      <div class="table-filter__item" v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['cuspayments_recepient'])"></span>
        <DefaultInput
            :label="$t('cuspayments_recepient.localization_value.value')"
            :type="'text'"
            v-model="recipientName"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_email'])"></span>
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            :type="'text'"
            v-model="recipientEmail"
        />
      </div>
			<div class="table-filter__item"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_date'])"></span>
				<DatePickerDefault
								:label="$t('cuspayments_date.localization_value.value')"
								v-model="date"
				>
					<template slot="body">
						<date-picker
										v-model="date"
										ref="datePicker"
										valueType="format"
										range
										:lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
										:format="'MM/DD/YY'"
										:placeholder="'mm/dd/yy'"
						></date-picker>
					</template>
				</DatePickerDefault>
			</div>

			<div class="table-filter__item"
					 v-if="userTabs.paypal.active"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
							@click="editTranslate(['cuspayments_shopType'])"></span>
				<DefaultSelect
								:options="shopTypesFilter"
								:label="$t('cuspayments_shopType.localization_value.value')"
								@change="changeShopTypesFilter"
								:optionsLabel="'fullName'"
								:selected="shopActive"
				/>
			</div>
			<div class="table-filter__item"
					 v-if="!userTabs.other.active"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_paypalTransactions'])"></span>
				<DefaultInput
								:label="
								userTabs.paypal.active ? $t('cuspayments_paypalTransactions.localization_value.value') :
									$t('cuspayments_transactions.localization_value.value')"
								:type="'text'"
								v-model="transactionNumber"
				/>
			</div>
			<div class="table-filter__item" v-if="userTabs.paypal.active || userTabs.authorize.active"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_trackingNumber'])"></span>
				<DefaultInput
								:label="$t('cuspayments_trackingNumber.localization_value.value')"
								:type="'text'"
								v-model="trackingNum"
				/>
			</div>
			<div class="table-filter__item"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_total'])"></span>
				<DefaultInput
								:label="$t('cuspayments_total.localization_value.value')"
								:type="'text'"
								v-model="total"
				/>
			</div>
			<div class="table-filter__item"
					 v-if="!userTabs.other.active || isAdmin"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_totalAmount'])"></span>
				<DefaultInput
								:label="$t('cuspayments_totalAmount.localization_value.value')"
								:type="'text'"
								v-model="totalAmount"
				/>
			</div>
			<div class="table-filter__item" v-if="userTabs.paypal.active"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['cuspayments_items'])"></span>
				<DefaultInput
								:label="$t('cuspayments_items.localization_value.value')"
								:type="'text'"
								v-model="items"
				/>
			</div>
			<div class="table-filter__item" v-if="userTabs.other.active"
					 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
				<span class="admin-edit" @click="editTranslate(['payments_comment'])"></span>
				<DefaultInput
								:label="$t('payments_comment.localization_value.value')"
								:type="'text'"
								v-model="comment"
				/>
			</div>
		</template>
		<template slot="footer">
			<MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
			<MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
		</template>
	</FilterBlock>
</template>

<script>
	import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
	import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
	import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
	import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
	import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
	import DatePicker from 'vue2-datepicker'
	import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";

	export default {
		name: "CusPaymentsFilter",
		components: {
      SearchSelect,
			FilterBlock,
			MainButton,
			DefaultInput,
			DatePickerDefault,
			DatePicker,
			DefaultSelect,
		},

		props: [
			'showFilter',
			'filterGetParams',
			'typeShops',
			'userTabs',
		],

		mixins: [filterFunc, usersFunc],

		created() {
			let interval = setInterval(() => {
				if(this.shopTypesFilter.length > 0){
					this.checkShopSelectActive()
					clearInterval(interval)
				}
			}, 50)
		},

		data() {
			return {
				date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
				order: this.filterGetParams.order ? this.filterGetParams.order : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
				recipientName: this.filterGetParams.recipientName ? this.filterGetParams.recipientName : '',
				recipientEmail: this.filterGetParams.recipientEmail ? this.filterGetParams.recipientEmail : '',
				transactionNumber: this.filterGetParams.transactionNumber ? this.filterGetParams.transactionNumber : '',
				total: this.filterGetParams.total ? this.filterGetParams.total : '',
				totalAmount: this.filterGetParams.totalAmount ? this.filterGetParams.totalAmount : '',
				trackingNum: this.filterGetParams.trackingNum ? this.filterGetParams.trackingNum : '',
				items: this.filterGetParams.items ? this.filterGetParams.items : '',
				shop: this.filterGetParams.shop ? this.filterGetParams.shop : '',
				comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',
				shopTypesFilter: this.generateShopsTypeInFilter(this.typeShops),

        options: [],
				shopActive: '',
			}
		},

		watch: {
			filterGetParams(newVal) {
				this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''
				this.order = newVal.order ? newVal.order : ''
        this.userName = newVal.userName ? newVal.userName : ''
				this.recipientName = newVal.recipientName ? newVal.recipientName : ''
				this.recipientEmail = newVal.recipientEmail ? newVal.recipientEmail : ''
        this.userId = newVal.userId ? newVal.userId : ''
				this.transactionNumber = newVal.transactionNumber ? newVal.transactionNumber : ''
				this.total = newVal.total ? newVal.total : ''
				this.totalAmount = newVal.totalAmount ? newVal.totalAmount : ''
				this.trackingNum = newVal.trackingNum ? newVal.trackingNum : ''
				this.items = newVal.items ? newVal.items : ''
				this.shop = newVal.shop ? newVal.shop : ''
				this.comment = newVal.comment ? newVal.comment : ''

				this.checkShopSelectActive()
			},

			typeShops(newVal) {
				this.shopTypesFilter = this.generateShopsTypeInFilter(newVal)
			}
		},

		methods: {

			checkShopSelectActive() {
				this.shopActive = this.checkShopSelectActiveMixin(this.shopTypesFilter, this.shop)
			},

			closeFilter() {
				this.$emit('close')
			},

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, true)
      },

			resetFilter() {
				let data = this.resetFilterParams(this,
					[
						'date',
						'order',
            'userName',
						'recipientName',
						'recipientEmail',
            'userId',
						'transactionNumber',
						'total',
						'totalAmount',
						'trackingNum',
						'items',
						'shop',
						'comment',
					]
				)

				this.$emit('changeFilter', data)
			},

			changeFilter() {
				let data = this.changeFilterParams(this, [
					'order',
          'userId',
          'userName',
					'recipientName',
					'recipientEmail',
					'transactionNumber',
					'total',
					'totalAmount',
					'trackingNum',
					'items',
					'shop',
					'comment',
				])
				data.date = this.changeDateParams(this, 'date')

				this.$emit('changeFilter', data)
			},

      changeUsersFilter(value) {
        this.userId = value.id
        this.userName = value.contacts[0].user_full_name
      },

			changeShopTypesFilter(val) {
				this.shop = val.id
			},
		},

	}
</script>

<style scoped>

</style>
