<template>
  <div class="table-card__item-content"
       :class="{show : item.show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number"
           @click="$emit('showTransactionInfo')"
      >
        #{{item.id}}
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row">
          <div class="table-card__item-col" v-if="!userTabs.balanceOutput.active">
            <div class="table-card__item-label">
              {{$t('payments_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item"
                      :deep="'payment_transaction_detail.description'"
                      :replaceValue="'—'"
              />
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              <template v-if="userTabs.balanceOutput.active">
                {{$t('requestFunds_requestNumber.localization_value.value')}}
              </template>
              <template v-else>
                {{$t('requestReturn_Status.localization_value.value')}}
              </template>
            </div>
            <div class="table-card__item-info">
              <div class="table-check-field">
                <div class=""
                     v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
                  <StatusIcoBtn :type="'dots'" class="mr-1 t-0"/>
                  {{$t('common_InProgress.localization_value.value')}}
                </div>
                <div class=""
                     v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS || item.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">
                  <StatusIcoBtn :type="'active'" class="mr-1 t-0"/>

                  <template v-if="item.status === 'output'">FR - {{item.balance_output_id}}
                  </template>
                  <template v-else>
                    {{$t('common_Paid.localization_value.value')}}
                  </template>
                </div>
                <div class=""
                     v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          item.status === PAYMENTS_STATUSES.ERROR_STATUS">
                  <StatusIcoBtn :type="'rejected'" class="mr-1 t-0"/>
                  {{$t('common_Rejected.localization_value.value')}}
                </div>
                <div class=""
                     v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">
                  <StatusIcoBtn :type="'star'" class="mr-1 t-0"/>
                  {{$t('common_statusNew.localization_value.value')}}
                </div>


<!--                <div>-->
<!--                  <div class=""-->
<!--                       v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">-->
<!--                    <StatusIcoBtn :type="'dots'" class="mr-1 t-0"/>-->
<!--                    {{$t('common_InProgress.localization_value.value')}}-->
<!--                  </div>-->
<!--                  <div class=""-->
<!--                       v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS || item.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">-->
<!--                    <StatusIcoBtn :type="'active'" class="mr-1 t-0"/>-->
<!--                    {{$t('common_Paid.localization_value.value')}}-->
<!--                  </div>-->
<!--                  <div class=""-->
<!--                       v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||-->
<!--                          item.status === PAYMENTS_STATUSES.ERROR_STATUS">-->
<!--                    <StatusIcoBtn :type="'rejected'" class="mr t-0"/>-->
<!--                    {{$t('common_Rejected.localization_value.value')}}-->
<!--                  </div>-->
<!--                  <div class=""-->
<!--                       v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">-->
<!--                    <StatusIcoBtn :type="'star'" class="mr-1 t-0"/>-->
<!--                    {{$t('common_statusNew.localization_value.value')}}-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('cuspayments_totalAmount.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.user_amount}}
            </div>
          </div>
          <!--<div class="table-card__item-col">-->
            <!--<div class="table-card__item-label">-->
              <!--Was Sent at-->
            <!--</div>-->
            <!--<div class="table-card__item-info">-->
              <!--27 Aug 2020, 2:40am-->
            <!--</div>-->
          <!--</div>-->
        </div>

        <div v-if="isAdmin" class="table-card__item-btn">
          <div class="table-right__item">
            <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
            />
          </div>
<!--          <div class="table-right__item">-->
<!--            <LinkButton-->
<!--                    :value="'Express Order'"-->
<!--                    @click.native="showChooseExpressOrder"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="table-right__ico">-->
<!--            <BelposhtaBtn/>-->
<!--          </div>-->
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  // import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  // import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
  // import BelposhtaBtn from "../../../../../../../coreComponents/BelposhtaBtn/BelposhtaBtn";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";

  export default {
    name: "CusPaymentsTableOtherMobile",
    components: {LinkButton, ValueHelper, StatusIcoBtn},

    props: ['item', 'selectedNow', 'userTabs'],

    data() {
      return {
        show: false,
        PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      }
    }

  }
</script>

<style scoped>

</style>
