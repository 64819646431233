<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :name="'Fund Request ID'"
          :value="'#180378'"
          :backgroundImage="'cus-payments'"
          class="cus-payments"
      >
        <template slot="body">
          <div class="card-detail-left__date mt-1">
            20 Oct, 2020
          </div>
        </template>
        <template slot="button">
          <span class="site-link brown text-decoration-underline mt-2">Check Payments History</span>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.cusPayments" class="detail-page__head-back">
                  <LinkBack
                      :value="'Transactions' "
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-brown">
              Edit Customers’ Payment
            </div>
            <div class="order-create__head-txt mb-0">
              Please, check the information below and correct the user you would like to send money
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="order-create__section-label section-label">
              User Who gets Payment
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--66 custom-col--sm-100">
                <DefaultInput
                    :label="'Full Name'"
                    :type="'text'"
                    v-model="name"
                />
              </div>
              <div class="order-create__col custom-col custom-col--33 custom-col--sm-100 mb-0"></div>
              <div class="order-create__col custom-col custom-col--33 custom-col--sm-100">
                <DefaultInput
                    :label="'Transaction Number'"
                    :type="'text'"
                    v-model="transactionNumber"
                    :disabled="true"
                />
              </div>
              <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50">
                <InputSum
                    :label="'Sum to Pay'"
                    :icoType="'dollar'"
                    v-model="valueSum"
                    :disabled="true"
                />
              </div>
              <div class="order-create__col custom-col custom-col--16 custom-col--md-25 custom-col--sm-50">
                <InputSum
                    :label="'Totally'"
                    :icoType="'dollar'"
                    v-model="valueTotal"
                    :disabled="true"
                />
              </div>
            </div>


            <div class="order-create__section-label section-label">
              Status
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--md--66 custom-col--sm-100">
                <DefaultSelect
                    :errorTxt="'Status'"
                    :options="options"
                    :label="'Status'"
                    :selected="'Completed'"
                />
              </div>
            </div>


          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.cusPayments"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto">

              <div class="order-create__footer-btn-i order-create__footer-btn-i--link">
                <span class="site-link site-link--alt">
                  Delete
                </span>
              </div>

              <MainButton
                  class="order-create__footer-btn-i"
                  :value="'Update'"
              />
            </div>
          </div>
        </template>
      </CardRightBlock>

    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";



  export default {
    name: "CusPaymentsEditAdmin",

    components: {
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      DefaultInput,
      InputSum,
      DefaultSelect,
    },

    mixins: [mixinDetictingMobile],

    data() {
      return {
        name: 'Belgorodsky Anton',
        transactionNumber: '3847598fu9e8498w7',
        valueSum: 100,
        valueTotal: 200,
        options: [{}],
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>

