<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <span
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['cuspayments_importTransaction',])"></span>
        {{$t('cuspayments_importTransaction.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="import-transaction__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
        'cuspayments_toImportTransaction',
        'importO_transactionNumber',
        'importO_transactionNumber',
        'common_cancel',
        'common_send',
        ])"></span>
        <div class="custom-popup__txt size-18px">
          {{$t('cuspayments_toImportTransaction.localization_value.value')}}
        </div>
        <div class="import-transaction__row custom-row">
          <div class="import-transaction__col custom-col mb-3">
            <RadioDefault
                :label="$t('importO_transactionNumber.localization_value.value')"
                :name="'transNumber'"
                @change.native="changeRadio(0)"
                :value="true"
            />
          </div>
          <div class="import-transaction__col custom-col custom-col--50 custom-col--sm-100" v-if="radioValue === 0">
            <DefaultInput
                class="ml-4"
                :label="$t('importO_transactionNumber.localization_value.value')"
                :type="'text'"
                v-model="number"
            />
          </div>

          <!--<div class="import-transaction__col custom-col">-->
            <!--<RadioDefault-->
                <!--:label="$t('common_uploadFile.localization_value.value')"-->
                <!--:name="'transNumber'"-->
                <!--@change.native="changeRadio(1)"-->
            <!--/>-->
          <!--</div>-->

          <div class="import-transaction__col custom-col" v-if="radioValue === 1">
            <DropZone class="drop-zone-bg"
                      :parentFiles="files"
                      @changeImg="changeImg"
                      :maxSize="'5'"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="importTransaction"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DefaultInput from '../../../../UI/inputs/DefaultInput/DefaultInput.vue'
  import RadioDefault from "../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import DropZone from "../../../../coreComponents/DropZone/DropZone";

  export default {
    name: "ImportTransactionPopup",
    components: {
      Modal,
      MainButton,
      DefaultInput,
      RadioDefault,
      DropZone,
    },

    data(){
      return {
        number: '',
        radioValue: 0,
        files: [],
      }
    },

    mounted() {

    },

    methods: {
      changeRadio(val){
        this.radioValue = val
      },

      changeImg(files) {
        this.files = files
      },

      importTransaction() {
        let data = {
          transaction_id: this.number
        }
        this.$store.dispatch('getImportTransaction', data).then((response) => {

          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            setTimeout(() => {
              this.openNotify('success', 'common_transactionAdded')
            }, 200)
          } else if(this._.has(response.response.data, 'transaction_id')) {
            setTimeout(() => {
              this.openNotify('error', 'common_transactionWillBeAdded')
            }, 200)
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_transactionAddedError')
            }, 200)
          }
        })
      },
    }
  }

</script>

<style scoped>
  .size-18px{
    font-size: 18px;
  }

  @media(max-width: 1150px){
    .size-18px{
      font-size: 16px;
    }
  }
</style>
