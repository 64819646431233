<template>
  <div class="fragment">
    <div v-if="$store.getters.getCusPaymentsCommonList.total" class="pagination-wrap mt-3">
      <PerPageSelect
          :currentPerPage="$store.getters.getCusPaymentsForPage"
          :cookieName="'perPageTransactions'"
          @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />
      <Pagination
          :count="$store.getters.getCusPaymentsCommonList.total"
          :perPage="$store.getters.getCusPaymentsForPage"
          :loading="$store.getters.getCusPaymentsLoading"
          @paginate="(page) => {$emit('paginate', page)}"
      />
    </div>

    <template v-if="$store.getters.getCusPayments.length > 0 && $store.getters.getCusPaymentsLoading !== true">
      <div class="site-table-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'cuspayments_iD',
          'cuspayments_store',
          'cuspayments_recepient',
          'cuspayments_date',
          'cuspayments_items',
          'cuspayments_paypalTransactions',
          'cuspayments_trackingNumber',
          'cuspayments_transactions',
          'cuspayments_total',
          'cuspayments_totalAmount',
          'cuspayments_createNew',
          'warehouseManagement_manage',
        ])"></span>

        <table class="site-table"  v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('cuspayments_iD.localization_value.value')}}</th>
            <th v-if="isAdmin">{{$t('common_user.localization_value.value')}}</th>
            <th>{{$t('cuspayments_date.localization_value.value')}}</th>
            <th v-if="userTabs.paypal.active || userTabs.braintree.active">{{$t('cuspayments_store.localization_value.value')}}</th>
            <th v-if="!isAdmin">{{$t('cuspayments_recepient.localization_value.value')}}</th>
            <th v-if="userTabs.paypal.active">{{$t('cuspayments_paypalTransactions.localization_value.value')}}</th>
            <th v-else>{{$t('cuspayments_transactions.localization_value.value')}}</th>
            <th>{{$t('cuspayments_trackingNumber.localization_value.value')}}</th>
            <th v-if="userTabs.paypal.active && isAdmin">Item</th>
            <th v-if="!isSubUser">{{$t('cuspayments_total.localization_value.value')}}</th>
            <th v-if="!isSubUser && !isAdmin">{{$t('cuspayments_totalAmount.localization_value.value')}}</th>
            <th><span v-if="isAdmin">{{$t('cuspayments_createNew.localization_value.value')}}</span> <span v-else>{{$t('warehouseManagement_manage.localization_value.value')}}</span></th>
          </tr>
          </thead>
          <tbody>


          <tr v-for="(item, index) in $store.getters.getCusPayments" :key="index">
            <td>
              <div class="table-card__item-number table-card__item-number--small">
                <template v-if="isSubUser">
                  <span class="table-link">#{{item.id}}</span>
                </template>
                <template v-else>
                  <span class="table-link btn-style" @click="showTransactionInfo(item.id)">#{{item.id}}</span>
                </template>
              </div>
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  :item="item"
              />
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="userTabs.paypal.active || userTabs.braintree.active">
              <template v-if="item.paypal_button">
                Paypal button
              </template>
              <template v-else-if="item.invoice && item.invoice.invoice_instance && _.has(item.invoice.invoice_instance.instance, 'etsy_listing_id')">
                <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['cuspayments_cuspayments_listing'])"></div>
                  {{$t('cuspayments_cuspayments_listing.localization_value.value')}}
                </div>

              </template>
              <template v-else>
                <router-link
                    v-if="item.shop"
                    class="table-link btn-style"
                    :to="$store.getters.GET_PATHS.mainSettingsShops + '?name=' + item.shop.shop_name + `${isAdmin ? '&verify=' + item.shop.shop_status : ''}`"
                >
                  {{item.shop.shop_name}}
                </router-link>
                <template v-else>
                  —
                </template>
              </template>
            </td>
            <td v-if="!isAdmin">
              <template v-if="item.payment_transaction_detail !== null &&
                item.payment_transaction_detail.payment_transaction_contact !== null && item.payment_transaction_detail.payment_transaction_contact.full_name">
                {{item.payment_transaction_detail.payment_transaction_contact.full_name}}
              </template>
              <template v-else-if="item.payment_transaction_detail !== null &&
                item.payment_transaction_detail.payment_transaction_contact !== null && item.payment_transaction_detail.payment_transaction_contact.first_name">
                {{item.payment_transaction_detail.payment_transaction_contact.first_name}}
              </template>
            </td>
            <td>
              {{item.transaction_id}}
            </td>
            <td>
              <div class="d-flex align-items-center"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['cuspayments_inProcess','importO_addNumber','importO_trackingNumber',])"></div>

                <TableTrackingLog
                    v-if="item.deliveryState"
                    class="mt-1"
                    :trackingLogItem="null"
                    :trackingStateEvent="item.deliveryState"
                />

                <template v-if="item.tracking_number !== null">
                  <div class="table-link btn-style">
                    <template v-if="item.tracking_number_state === null && item.tracking_number_url && isAdmin"><a target="_blank" class="table-link btn-style"
                         :href="item.tracking_number_url">{{item.tracking_number}}</a></template>
                    <span v-else-if="item.tracking_number_state === null">{{item.tracking_number}}</span>
                    <span v-else>{{$t('cuspayments_inProcess.localization_value.value')}}</span>
                  </div>
                  <TooltipTextHelperPlugin :fullTextWidth="300" :trigger="'click'">
                    <template slot="text">
                        <span class="site-link">
                            <LinkButton
                                v-if="item.tracking_number_state === null"
                                :type="'edit'"/>
                        </span>
                    </template>
                    <template slot="paragraph">
                      <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                      <InputEditField
                          :value="item.tracking_number"
                          :propId="item.id"
                          :hideEditField="hideEditField"
                          @onChange="(data) => changeTrackingNumber(data, item.transaction_id, index, item)"
                      />
                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </TooltipTextHelperPlugin>
                </template>
                <template v-else>
                  <TooltipTextHelperPlugin :fullTextWidth="300" :trigger="'click'">
                    <template slot="text">
                      <span class="table-link btn-style" v-if="item.tracking_number_state === null">{{ $t('importO_addNumber.localization_value.value') }}</span>
                      <span class="table-link btn-style" v-else>{{$t('cuspayments_inProcess.localization_value.value')}}</span>
                    </template>
                    <template slot="paragraph">
                      <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                      <InputEditField
                          :value="''"
                          :propId="item.id"
                          :hideEditField="hideEditField"
                          @onChange="(data) => createTrackingNumber(data, item.transaction_id, item)"
                      />
                      <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                    </template>
                  </TooltipTextHelperPlugin>
                </template>
              </div>
            </td>
            <td v-if="userTabs.paypal.active && isAdmin">
              <div>
                <TooltipTextHelper
                    :text="item.payment_transaction_detail.items"
                    :paragraph="item.payment_transaction_detail.items"
                    :fullTextWidth="140"
                />
              </div>
            </td>
            <td v-if="!isSubUser && !isAdmin">
              <div class="white-space-nowrap">
                <template v-if="userTabs.authorize.active">{{item.user_amount}}</template>
                <template v-if="userTabs.paypal.active">
                  <span v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                  {{item.user_amount}}
                </template>
                <template v-if="userTabs.braintree.active">
                  <span v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                  {{item.user_amount}}
                </template>
              </div>
            </td>
            <td v-if="!isSubUser">
              <template v-if="userTabs.authorize.active">{{item.payment_total}}</template>
              <template v-if="userTabs.paypal.active">
                <span v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                {{item.payment_total}}
              </template>
              <template v-if="userTabs.braintree.active">
                <span v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                {{item.payment_total}}
              </template>
            </td>

            <td>
              <ManageOrdersInTable
                   v-if="item.canMakeRefund"
                   :item="item"
                   :typeTransaction="'payment'"
                   @showChooseExpressOrder="showChooseExpressOrder"
                   @showChooseOrderFBMCreatePopup="showChooseOrderFBMCreatePopup"
              />
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-else>
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getCusPayments"
                 :key="index"
            >
              <CusPaymentsTableMobile
                      :item="item"
                      :index="index"
                      :userTabs="userTabs"
                      @changeTrackingNumber="changeTrackingNumber"
                      @createTrackingNumber="createTrackingNumber"
                      @showTransactionInfo="showTransactionInfo"
                      @showChooseOrderFBMCreatePopup="showChooseOrderFBMCreatePopup"
                      @showChooseExpressOrder="showChooseExpressOrder"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                      v-if="$store.getters.getCusPaymentsCommonList.next_page_url !== null && $store.getters.getCusPayments.length > 0"
                      @click.native="$emit('showMore')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNextCusPaymentsPage}"
                      :count="$store.getters.getCusPaymentsCommonList.total - $store.getters.getCusPaymentsForPage * $store.getters.getCusPaymentsCommonList.current_page < $store.getters.getCusPaymentsForPage ?
                  $store.getters.getCusPaymentsCommonList.total - $store.getters.getCusPaymentsForPage * $store.getters.getCusPaymentsCommonList.current_page:
                  $store.getters.getCusPaymentsForPage"
            />
            <ExportBtn
                :customType="_.has($store.getters.getCusPaymentsCommonList, 'addition_export') ? $store.getters.getCusPaymentsCommonList.addition_export : null"
                    @downloadFiles="type => $emit('downloadFiles', type)"
                    class="table-bottom-btn__right"/>
          </div>
        </div>

      </div>
    </template>
    <template v-if="$store.getters.getCusPaymentsLoading === false && $store.getters.getCusPayments.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <TransactionInfo
            @close="closeTransactionInfo"
            v-if="isModalTransactionInfo"
            :userTabs="userTabs"
    />

    <TransactionInfoAdmin
        @close="closeTransactionInfoAdmin"
        v-if="isModalTransactionInfoAdmin"
        :userTabs="userTabs"
        :transactionUrl="true"
    />

    <ChooseOrderFBMCreatePopup
            @close="closeChooseOrderFBMCreatePopup"
            v-if="isModalChooseOrderFBMCreatePopup"
            :consolidation="_.has(this.currentPermissions, PERMISSIONS.ORDER_FBM_CONSOLIDATION) ? orderFBMLinks.consolidation : null"
            :warehouse="_.has(currentPermissions, PERMISSIONS.ORDER_FBM_WAREHOUSE) ? orderFBMLinks.warehouse : null"
            :label="_.has(currentPermissions, PERMISSIONS.ORDER_FBM_LABEL) ? orderFBMLinks.label : null"
    />

    <ChooseExpressOrderPopup
            @close="closeChooseExpressOrder"
            v-if="isModalChooseExpressOrder"
            :dhl="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_DHL) ? expressLinks.dhl : null"
            :fedex="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_FEDEX) ? expressLinks.fedex : null"
            :tnt="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_TNT) ? expressLinks.tnt : null"
            :npg="_.has(this.currentPermissions, PERMISSIONS.ORDER_EXPRESS_NPG) ? expressLinks.npg : null"
    />


    <CommentsPopup
            v-if="isModalCommentsPopup"
            @close="changeCommentsPopup(false)"
    />

    <SendEmailPopup
            v-if="isModalSendEmailPopup"
            @close="changeSendEmailPopup(false)"
    />

    <LastFiveIPPopup
            v-if="isModalLastFiveIPPopup"
            @close="changeLastFiveIPPopup(false)"
    />

    <StatisticPopup
            v-if="isModalStatisticPopup"
            @close="changeStatisticPopup(false)"
    />


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import TransactionInfoAdmin from "../../../../popups/TransactionInfoAdmin/TransactionInfoAdmin.vue";
  import TransactionInfo from "../../../../popups/TransactionInfo/TransactionInfo.vue";
  import CommentsPopup from "../../../../popups/CommentsPopup/CommentsPopup";
  import SendEmailPopup from "../../../../popups/SendEmailPopup/SendEmailPopup";
  import LastFiveIPPopup from "../../../../popups/LastFiveIPPopup/LastFiveIPPopup";
  import StatisticPopup from "../../../../popups/StatisticPopup/StatisticPopup";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import CusPaymentsTableMobile from "./CusPaymentsTableMobile/CusPaymentsTableMobile";
  import {
    CUSTOMERS_PAYMENTS_STATUSES,
    CUSTOMERS_PAYMENTS_TRACKING_NUMBER_STATE,
    EXPRESS_FROM_TRANSACTIONS,
    INTERNAL_DELIVERY_SERVICES,
  } from "../../../../../../../staticData/staticVariables";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import ChooseExpressOrderPopup from "../../../../../../coreComponents/Popups/ChooseExpressOrder/ChooseExpressOrderPopup";
  import ChooseOrderFBMCreatePopup from "../../../../../../coreComponents/Popups/ChooseOrderFBMCreatePopup/ChooseOrderFBMCreatePopup";
  import ManageOrdersInTable
    from "../../../../../../coreComponents/TableComponents/ManageOrdersInTable/ManageOrdersInTable";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {trackingNumberFunc} from "../../../../../../../mixins/trackingNumberMixins/trackingNumberFunc";
  import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
  import Pagination from "../../../../../../coreComponents/Pagination/Pagination";
  import PerPageSelect from "../../../../../../coreComponents/PerPageSelect/PerPageSelect";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";

  export default {
    name: "CusPaymentsTable",
    components: {
      TableTrackingLog,
      TooltipTextHelperPlugin,
      PerPageSelect,
      Pagination,
      InputEditField,
      TableUserColumn,
      ManageOrdersInTable,
      TooltipTextHelper,
      ShowMore,
      ExportBtn,
      LinkButton,
      TransactionInfo,
      TransactionInfoAdmin,
      ChooseOrderFBMCreatePopup,
      ChooseExpressOrderPopup,
      CommentsPopup,
      SendEmailPopup,
      LastFiveIPPopup,
      StatisticPopup,
      NoResult,
      CusPaymentsTableMobile,
    },

    mixins: [mixinDetictingMobile, trackingNumberFunc],

    props: ['activeTab', 'userTabs', 'countFilterParams'],

    watch: {
      activeTab(newVal) {
        this.activeTab = newVal
      },

      userTabs(newVal) {
        this.userTabs = newVal
      },
    },

    computed: {
      // linkCreateDHL() {
      //   if(!this.order.order_express_attachment)
      //     return `/create/dhl/${EXPRESS_FROM_TRANSACTIONS.PAYMENT.name}/${this.order.id}`
      //
      //   if(this.order.order_express_attachment?.delivery_service_id ===  DHL_DELIVERY_SERVICE.id){
      //     return `/edit/dhl/${this.order.order_express_attachment.id}`
      //   }
      //
      //   return null
      // },
      //
      // linkCreateFedex() {
      //   if(!this.order.order_express_attachment)
      //     return `/create/fedex/${EXPRESS_FROM_TRANSACTIONS.PAYMENT.name}/${this.order.id}`
      //
      //   if(this.order.order_express_attachment?.delivery_service_id ===  FEDEX_DELIVERY_SERVICE.id){
      //     return `/edit/fedex/${this.order.order_express_attachment.id}`
      //   }
      //
      //   return null
      // },
      //
      // linkCreateTNT() {
      //   let link = null
      //
      //   if(!this.order.order_express_attachment && this.checkUkrainianUserInTNT(TNT_DELIVERY_SERVICE.id)){
      //     link = `/create/tnt/${EXPRESS_FROM_TRANSACTIONS.PAYMENT.name}/${this.order.id}`
      //     return link
      //   }
      //
      //   if(this.order.order_express_attachment && this.order.order_express_attachment.delivery_service_id ===  TNT_DELIVERY_SERVICE.id){
      //     link = `/edit/tnt/${this.order.order_express_attachment.id}`
      //     return link
      //   }
      //
      //   return link
      // },

    },

    data() {
      return {
        show1: false,
        show2: false,

        isModalChooseOrderFBMCreatePopup: false,
        isModalChooseExpressOrder: false,

        isModalTransactionInfo: false,
        isModalTransactionInfoAdmin: false,


        isModalCommentsPopup: false,
        isModalSendEmailPopup: false,
        isModalLastFiveIPPopup: false,
        isModalStatisticPopup: false,

        showItemId: false,

        EXPRESS_FROM_TRANSACTIONS: EXPRESS_FROM_TRANSACTIONS,
        CUSTOMERS_PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        CUSTOMERS_PAYMENTS_TRACKING_NUMBER_STATE: CUSTOMERS_PAYMENTS_TRACKING_NUMBER_STATE,
        INTERNAL_DELIVERY_SERVICES: INTERNAL_DELIVERY_SERVICES,

        items: [
          {
            item: 'empty',
            show: false
          },
        ],

        orderId: -1,
        order: {},
        expressLinks: {},

        orderFBMLinks: {
          consolidation: null,
          warehouse: null,
          label: null,
        },

        showItemType: 'paypal',
        hideEditField: false,
      }
    },

    mounted() {
    },

    methods: {
      showChooseOrderFBMCreatePopup (order, links) {
        this.orderFBMLinks = links
        this.order = order
        this.isModalChooseOrderFBMCreatePopup = true
      },

      closeChooseOrderFBMCreatePopup () {
        this.isModalChooseOrderFBMCreatePopup = false
      },

      showChooseExpressOrder (order, links) {
        this.expressLinks = links
        this.order = order
        this.isModalChooseExpressOrder = true
      },

      closeChooseExpressOrder () {
        this.order = {}
        this.isModalChooseExpressOrder = false
      },

      // showTransactionInfoAdmin() {
      //   this.isModalTransactionInfoAdmin = true
      // },

      closeTransactionInfoAdmin() {
        this.isModalTransactionInfoAdmin = false
      },

      showTransactionInfo(id) {
        this.$store.dispatch('getCusPayments', id).then(() => {
          if(this.isAdmin) {
            this.isModalTransactionInfoAdmin = true
            return
          }
          this.isModalTransactionInfo = true
        })
      },

      closeTransactionInfo() {
        this.isModalTransactionInfo = false
      },

      changeCommentsPopup(val) {
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val) {
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val) {
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val) {
        this.isModalStatisticPopup = val
      },

    },
  }
</script>

<style scoped lang="scss">
table td:last-child,
table th:last-child {
  padding-right: 0;
}

/deep/ .max-width-100 .tooltip-helper-full {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/deep/ .w100.manager-btn {
  width: 100px;
}

  .table-card__item-number {
    margin-bottom: 0px;
  }

  .import-orders-table table td:last-child,
  .import-orders-table table th:last-child {
    width: 270px;
  }

  @media (max-width: 1600px) {
    .table-right__ico {
      margin-left: 10px;
    }
  }

  .table-card__item-btn-i:first-child{
    padding-left: 5px;
  }
</style>
