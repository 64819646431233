<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      #{{getCusPaymentsItem.id}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'payments_NewPayment',
          'common_Amount',
          'payments_comment',
          'requestFunds_requestNumber',
          'requestReturn_Status',
        ])"></span>

        <div class="transaction-info__name"
             v-if="item = getCusPaymentsItem"
        >
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
            {{$t('common_InProgress.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS || item.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">
            <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
            {{$t('common_Paid.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          item.status === PAYMENTS_STATUSES.ERROR_STATUS">
            <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
            {{$t('common_Rejected.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">
            <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
            {{$t('common_statusNew.localization_value.value')}}
          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__row">
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('payments_Created.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ item.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('common_Amount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="getCusPaymentsItem.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">
                  {{getCusPaymentsItem.user_amount}}
                </template>
                <template v-else>
                  {{getCusPaymentsItem.payment_total}}
                </template>
              </div>
            </div>
            <div class="custom-col" v-if="!userTabs.balanceOutput.active">
              <div class="transaction-info__label">
                {{$t('payments_comment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        v-if="getCusPaymentsItem.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS"
                        :value="getCusPaymentsItem"
                        :deep="'balance_output.user_comment'"
                        :replaceValue="'—'"
                />
                <ValueHelper
                        v-else
                        :value="getCusPaymentsItem"
                        :deep="'payment_transaction_detail.description'"
                        :replaceValue="'—'"
                />
              </div>
            </div>

            <div class="custom-col" v-if="item.status">
              <div class="transaction-info__label">
                <template v-if="userTabs.balanceOutput.active">
                  {{$t('requestFunds_requestNumber.localization_value.value')}}
                </template>
                <template v-else>
                  {{$t('requestReturn_Status.localization_value.value')}}
                </template>
              </div>
              <div class="transaction-info__txt">

                <div>
                  <div class=""
                       v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
                    <StatusIcoBtn :type="'dots'" class="mr-1 t-0"/>
                    {{$t('common_InProgress.localization_value.value')}}
                  </div>
                  <div class=""
                       v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS || item.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">
                    <StatusIcoBtn :type="'active'" class="mr-1 t-0"/>

                    <template v-if="item.status === 'output'">
<!--                      FR - {{item.balance_output_id}}-->
                      <template v-if="item.balance_output_id">FR - {{item.balance_output_id}}</template><template v-else>SP - {{item.id}}</template>
                    </template>
                    <template v-else>
                      {{$t('common_Paid.localization_value.value')}}
                    </template>
                  </div>
                  <div class=""
                       v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          item.status === PAYMENTS_STATUSES.ERROR_STATUS">
                    <StatusIcoBtn :type="'rejected'" class="mr t-0"/>
                    {{$t('common_Rejected.localization_value.value')}}
                  </div>
                  <div class=""
                       v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">
                    <StatusIcoBtn :type="'star'" class="mr-1 t-0"/>
                    {{$t('common_statusNew.localization_value.value')}}
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="custom-col"-->
<!--                 v-if="getCusPaymentsItem.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">-->
<!--              <div class="transaction-info__label">-->
<!--                {{$t('payments_commentAdmin.localization_value.value')}}-->
<!--              </div>-->
<!--              <div class="transaction-info__txt">-->
<!--                <ValueHelper-->
<!--                        :value="getCusPaymentsItem"-->
<!--                        :deep="'balance_output.admin_comment'"-->
<!--                        :replaceValue="'—'"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
          </div>

        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../staticData/staticVariables";
  import {mapGetters} from "vuex";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "InfoIDPopup",
    components: {
      ValueHelper,
      StatusIcoBtn,
      Modal,
    },

    props: [
      'userTabs'
    ],

    computed: {
      ...mapGetters([
        'getCusPaymentsItem',
      ])
    },

    data() {
      return{
        PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
      }
    }
  }

</script>

<style lang="scss">

</style>
