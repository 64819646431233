<template>
  <div class="table-card__item-content"
       :class="{show : show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-number">
        <template v-if="isSubUser">
          <span>#{{ item.id }}</span>
        </template>
        <template v-else>
          <span @click="$emit('showTransactionInfo', item.id)">#{{ item.id }}</span>
        </template>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
        <div class="table-card__item-head-txt" v-if="item.payment_transaction_detail !== null">
          <template v-if="item.payment_transaction_detail !== null &&
                item.payment_transaction_detail.payment_transaction_contact !== null">
            {{item.payment_transaction_detail.payment_transaction_contact.full_name}}
          </template>
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  :item="item"
                  :mobile="true"
              />
            </div>
          </div>

          <div class="table-card__item-col"
               v-if="userTabs.paypal.active && item.payment_transaction_detail !== null &&
               item.payment_transaction_detail.items &&
               item.payment_transaction_detail.items.length > 0 &&
               item.payment_transaction_detail.items[0].name">
            <div class="table-card__item-label">
              {{$t('cuspayments_items.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.payment_transaction_detail.items[0].name}}
            </div>
          </div>
          <div class="table-card__item-col">
            <div class="table-card__item-label">
              <template v-if="userTabs.paypal.active">{{$t('cuspayments_paypalTransactions.localization_value.value')}}</template>
              <template v-else>{{$t('cuspayments_transactions.localization_value.value')}}</template>
            </div>
            <div class="table-card__item-info">
              {{item.transaction_id}}
            </div>
          </div>
        </div>

        <div class="table-card__item-row custom-row">
          <div v-if="userTabs.paypal.active || userTabs.braintree.active" class="table-card__item-col">
            <div class="table-card__item-label">
              {{$t('cuspayments_store.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.paypal_button">
                Paypal button
              </template>
              <template v-else-if="item.invoice && item.invoice.invoice_instance && _.has(item.invoice.invoice_instance.instance, 'etsy_listing_id') && !isAdmin">
                <div
                    v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['cuspayments_cuspayments_listing'])"></div>
                  {{$t('cuspayments_cuspayments_listing.localization_value.value')}}
                </div>

              </template>
              <template v-else>
                <router-link
                    v-if="item.shop"
                    class="table-link btn-style"
                    :to="$store.getters.GET_PATHS.mainSettingsShops + '?name=' + item.shop.shop_name + `${isAdmin ? '&verify=' + item.shop.shop_status : ''}`"
                >
                  {{item.shop.shop_name}}
                </router-link>
                <template v-else>
                  —
                </template>
              </template>
            </div>
          </div>
          <div class="table-card__item-col"
               v-if="userTabs.paypal.active && isAdmin"
          >
            <div class="table-card__item-label">
              Items
            </div>
            <div class="table-card__item-info">
              {{ item.payment_transaction_detail.items }}
            </div>
          </div>
        </div>


        <div class="table-card__item-row">
          <div class="table-card__item-col">

            <TableTrackingLog
                v-if="item.deliveryState"
                class="mt-1"
                :trackingLogItem="null"
                :trackingStateEvent="item.deliveryState"
            />

            <div class="table-card__item-label">
              {{$t('cuspayments_trackingNumber.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="item.tracking_number !== null">
                <div class="table-link btn-style">
                  <template v-if="item.tracking_number_state === null && item.tracking_number_url && isAdmin"><a target="_blank" class="table-link btn-style"
                      :href="item.tracking_number_url">{{item.tracking_number}}</a></template>
                  <span v-else-if="item.tracking_number_state === null">{{item.tracking_number}}</span>
                  <span v-else>{{$t('cuspayments_inProcess.localization_value.value')}}</span>
                </div>
                <TooltipTextHelperPlugin :fullTextWidth="300" :trigger="'click'">
                  <template slot="text">
                      <span class="site-link">
                          <LinkButton
                                  v-if="item.tracking_number_state === null"
                                  :type="'edit'"/>
                      </span>
                  </template>
                  <template slot="paragraph">
                    <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                    <InputEditField
                            :value="item.tracking_number"
                            :propId="item.id"
                            :hideEditField="hideEditField"
                            @onChange="changeTrackingNumber"
                    />
                    <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                  </template>
                </TooltipTextHelperPlugin>
              </template>

              <template v-else>
                <TooltipTextHelperPlugin :fullTextWidth="300" :trigger="'click'">
                  <template slot="text">
                    <span class="table-link btn-style" v-if="item.tracking_number_state === null">{{ $t('importO_addNumber.localization_value.value') }}</span>
                    <span class="table-link btn-style" v-else>{{$t('cuspayments_inProcess.localization_value.value')}}</span>
                  </template>
                  <template slot="paragraph">
                    <div class="section-label mb-2">{{ $t('importO_trackingNumber.localization_value.value') }}</div>
                    <InputEditField
                            :value="''"
                            :propId="item.id"
                            :hideEditField="hideEditField"
                            @onChange="createTrackingNumber"
                    />
                    <a v-close-popover ref="closePopover" class="tooltip__close"></a>
                  </template>
                </TooltipTextHelperPlugin>
              </template>
            </div>
          </div>
        </div>

        <div class="table-card__item-row">
          <div class="table-card__item-col" v-if="!isSubUser && !isAdmin">
            <div class="table-card__item-label">
              {{$t('cuspayments_total.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.user_amount}}
            </div>
          </div>
          <div class="table-card__item-col" v-if="!isSubUser">
            <div class="table-card__item-label">
              {{$t('cuspayments_totalAmount.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <template v-if="userTabs.authorize.active">{{item.payment_total}}</template>
              <template v-if="userTabs.paypal.active">
                <span v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                {{item.payment_total}}
              </template>
              <template v-if="userTabs.braintree.active">
                <span v-if="item.status == CUSTOMERS_PAYMENTS_STATUSES.REFUND_TRANSACTION_STATUS">-</span>
                {{item.payment_total}}
              </template>
            </div>
          </div>
        </div>

        <div class="table-card__item-btn">
          <ManageOrdersInTable
                  v-if="item.canMakeRefund"
                  :item="item"
                  :typeTransaction="'payment'"
                  @showChooseExpressOrder="showChooseExpressOrder"
                  @showWhatOrderCreatePopup="showWhatOrderCreatePopup"
                  @showChooseOrderFBMCreatePopup="showChooseOrderFBMCreatePopup"
          />
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_mobileTableShowMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManageOrdersInTable from "../../../../../../../coreComponents/TableComponents/ManageOrdersInTable/ManageOrdersInTable";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {trackingNumberFunc} from "../../../../../../../../mixins/trackingNumberMixins/trackingNumberFunc";
  import InputEditField from "../../../../../../../UI/inputs/InputEditField/InputEditField";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import TooltipTextHelperPlugin from "@/components/coreComponents/TooltipTextHelperPlugin/TooltipTextHelperPlugin";
  import TableTrackingLog from "@/components/coreComponents/TableComponents/TableTrackingLog/TableTrackingLog";

  export default {
    name: "CusPaymentsTableMobile",

    components: {
      TableTrackingLog,
      TooltipTextHelperPlugin,
      InputEditField,
      TableUserColumn,
      ManageOrdersInTable,
      LinkButton,
    },

    mixins: [trackingNumberFunc, mixinDetictingMobile],

    props: ['userTabs', 'item', 'index'],

    data() {
      return {
        CUSTOMERS_PAYMENTS_STATUSES:CUSTOMERS_PAYMENTS_STATUSES,
        hideEditField: false,
        show: false,
      }
    },

    methods: {
      showChooseExpressOrder(itemEmit, links) {
        this.$emit('showChooseExpressOrder', itemEmit, links)
      },

      showWhatOrderCreatePopup(itemEmit) {
        this.$emit('showWhatOrderCreatePopup', itemEmit)
      },

      showChooseOrderFBMCreatePopup(itemEmit, links) {
        this.$emit('showChooseOrderFBMCreatePopup', itemEmit, links)
      },

      changeTrackingNumber({value, id}) {
        this.$emit('changeTrackingNumber', {value: value, id: id, ref: this.$refs.closePopover}, this.item.transaction_id, this.index, this.item)
      },

      createTrackingNumber({value, id}) {
        this.$emit('createTrackingNumber', {value: value, id: id, ref: this.$refs.closePopover}, this.item.transaction_id, this.item)
      },
    },
  }
</script>

<style scoped lang="scss">

  /deep/ .table-card__item-btn .table-right__ico{
    margin-left: 15px;
    margin-top: -1px;
  }

</style>
