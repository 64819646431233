<template>
  <modal
      @close="$emit('close')"
      class="what-order-create-modal what-order-create"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['fbm_ChooseOrderType'])"></div>
        {{$t('fbm_ChooseOrderType.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="what-order-create__list custom-row">
        <div class="what-order-create__item custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
             v-if="consolidation"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_OrderForConsolidation'])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersFBM + consolidation"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <OrderForConsolidationIco/>
            </div>
            {{$t('fbm_OrderForConsolidation.localization_value.value')}}
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
             v-if="warehouse"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_OrderFromWarehouse'])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersFBM + warehouse"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <OrderFromWarehouseIco/>
            </div>
            {{$t('fbm_OrderFromWarehouse.localization_value.value')}}
          </router-link>
        </div>
        <div class="what-order-create__item custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
             v-if="label"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['fbm_CreateLabel'])"></div>
          <router-link
              :to="$store.getters.GET_PATHS.ordersFBM + label"
              class="what-order-create__link"
          >
            <div class="what-order-create__ico">
              <CreateLabelIco/>
            </div>
            {{$t('fbm_CreateLabel.localization_value.value')}}
          </router-link>
        </div>
        <!--<div class="what-order-create__item custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;sm-100">-->
          <!--<router-link-->
              <!--:to="$store.getters.GET_PATHS.ordersFBMCreationEasy"-->
              <!--class="what-order-create__link"-->
          <!--&gt;-->
            <!--<div class="what-order-create__ico">-->
              <!--<OrderForEasyIco/>-->
            <!--</div>-->
            <!--Easy Order-->
          <!--</router-link>-->
        <!--</div>-->
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../commonModals/Modal.vue'

  import CreateLabelIco from '../../../../../public/img/modal-group/create-label.svg?inline'
  import OrderFromWarehouseIco from '../../../../../public/img/modal-group/Order-from-Warehouse.svg?inline'
  import OrderForConsolidationIco from '../../../../../public/img/modal-group/Order-for-Consolidation.svg?inline'
  // import OrderForEasyIco from '../../../../../../public/img/modal-group/Order-from-Easy.svg?inline'
  import {FBM_ORDER_TYPES} from "../../../../staticData/staticVariables";


  export default {
    name: "ChooseOrderFBMCreatePopup",

    components: {
      Modal,
      CreateLabelIco,
      OrderFromWarehouseIco,
      OrderForConsolidationIco,
      // OrderForEasyIco,
    },

    props: {
      consolidation: {
        type: String,
        default: '',
      },
      warehouse: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
    },

    data() {
      return {
        FBM_ORDER_TYPES: FBM_ORDER_TYPES,
      }
    },

  }

</script>

<style lang="scss">
  @import "../../../../scss/mixins/mixins";
  @import "../../../../scss/colors";

  /*.what-order-create{

    .modal-component__inner {
      max-width: 846px;
    }

    &__list{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;
      margin-bottom: -15px;
    }

    &__item{
      width: 25%;
      padding:  0 8px;
      margin-bottom: 15px;

      @include for-1120{
        width: 50%;
      }

      @include for-768{
        width: 100%;
      }
    }

    &__link{
      display: flex;
      height: 124px;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-radius: 5px;
      transition: .15s;
      padding: 30px;
      position: relative;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      color: $brown;


      @include for-768{
        padding: 15px;
        height: 70px;
      }

      &:before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        border: 2px solid $accent;
        border-radius: 5px;
      }

      &:hover{
        background-color: $accent;
        color: white;
      }

    }

    &__ico{
      position: absolute;
      top: 50%;
      left: 0;
      opacity: 0.6;
      transform: translateY(-50%) translateX(30%);
      transition: .15s;

      @include for-768{
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(0.7);
      }
    }

    &__link:hover &__ico{
      opacity: 0;
    }



  }*/

</style>
