<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size"
  >
    <template slot="header">
      <span
        v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['cuspayments_importTrackNumById',])"></span>
        {{$t('cuspayments_importTrackNumById.localization_value.value')}}
      </span>
    </template>
    <template slot="body">
      <div class="import-transaction__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
        'cuspayments_importTrackNumByIdText',
        'common_cancel',
        'common_send',
        ])"></span>
        <div class="custom-popup__txt size-18px">
          {{$t('cuspayments_importTrackNumByIdText.localization_value.value')}}
        </div>
        <div class="import-transaction__row custom-row">
          <div class="import-transaction__col custom-col">
            <DropZone class="drop-zone-bg"
                      v-bind:class="{'ui-no-valid': valid}"
                      :error="valid"
                      :parentFiles="files"
                      @changeImg="changeImg"
                      :accept="'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                      :maxSize="'5'"
            />
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="import-transaction__btn d-flex align-items-center flex-wrap">
        <span
            class="site-link site-link--alt import-transaction__btn-i mt-3 mr-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>

        <MainButton
            :value="$t('common_send.localization_value.value')"
            class="import-transaction__btn-i"
            @click.native="importTrackById"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import DropZone from "../../../../coreComponents/DropZone/DropZone";

  export default {
    name: "ImportTrackByIdPopup",
    components: {
      Modal,
      MainButton,
      DropZone,
    },

    data(){
      return {
        files: [],
        valid: false,
      }
    },

    mounted() {

    },

    methods: {

      changeImg(files) {
        this.files = files
      },

      importTrackById() {
        if (this.files.length === 0) {
          this.valid = true
        }
        if (this.files.length > 0) {
          this.valid = false
          let reader = new FormData();
          reader.append("import-file", this._.first(this.files).file)

          this.$store.dispatch('importTransactionTrackById', reader).then((response) => {

            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

                console.log('success');

                let text = this.getRespData(response)?.message

                this.openNotify('success', {txtServer: text}, 6000)
                // this.openNotify('success', 'common_notificationRecordChanged')
                this.$emit('reloadTable')
                this.$emit('close')

                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })
        }

      },
    }
  }

</script>

<style scoped>
  .size-18px{
    font-size: 18px;
  }

  @media(max-width: 1150px){
    .size-18px{
      font-size: 16px;
    }
  }
</style>
