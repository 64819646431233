<template>
  <div class="fragment">
    <div v-if="$store.getters.getCusPaymentsCommonList.total" class="pagination-wrap mt-3">
      <PerPageSelect
          :currentPerPage="$store.getters.getCusPaymentsForPage"
          :cookieName="'perPageTransactions'"
          @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />
      <Pagination
          :count="$store.getters.getCusPaymentsCommonList.total"
          :perPage="$store.getters.getCusPaymentsForPage"
          :loading="$store.getters.getCusPaymentsLoading"
          @paginate="(page) => {$emit('paginate', page)}"
      />
    </div>

    <template v-if="$store.getters.getCusPayments.length > 0 && $store.getters.getCusPaymentsLoading !== true">
        <div class="site-table-wrap small-table"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate([
            'cuspayments_iD',
            'cuspayments_date',
            'payments_comment',
            'requestReturn_Status',
            'requestFunds_requestNumber',
            'cuspayments_totalAmount',
          ])"></span>
        <table class="site-table"  v-if="!isMobileFunc()">
          <thead>
          <tr>
            <th>{{$t('cuspayments_iD.localization_value.value')}}</th>
            <th>{{$t('cuspayments_date.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{ $t('common_user.localization_value.value') }}</th>
            <th v-if="userTabs.other.active">{{$t('payments_comment.localization_value.value')}}</th>
            <th v-if="userTabs.other.active">{{$t('requestReturn_Status.localization_value.value')}}</th>
            <th v-if="userTabs.balanceOutput.active">{{$t('requestFunds_requestNumber.localization_value.value')}}</th>
            <th>{{$t('cuspayments_totalAmount.localization_value.value')}}</th>
            <th width="100%"></th>
            <th v-if="isAdmin">{{$t('warehouseManagement_manage.localization_value.value')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in $store.getters.getCusPayments" :key="index">
            <td>
              <span class="table-link btn-style"
                    @click="showTransactionInfo(item.id)">
                #{{item.id}}
              </span>
            </td>
            <td>{{ item.created_at | moment("DD MMM, YYYY") }}</td>
            <td  v-if="userTabs.other.active">

              <TooltipTextHelper>
                <template slot="text">
                  <ValueHelper
                      :value="item"
                      :deep="'payment_transaction_detail.description'"
                      :replaceValue="'—'"
                  />
                </template>
                <template slot="paragraph">
                  <p>
                    <ValueHelper
                        :value="item"
                        :deep="'payment_transaction_detail.description'"
                        :replaceValue="'—'"
                    />
                  </p>
                </template>
              </TooltipTextHelper>
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  :item="item"
              />
            </td>
            <td>
              <div>
                <div class="d-flex"
                     v-if="item.status === PAYMENTS_STATUSES.IN_PROGRESS_TRANSACTION_STATUS">
                  <StatusIcoBtn :type="'dots'" class="mr-2 t-0"/>
                  {{$t('common_InProgress.localization_value.value')}}
                </div>
                <div class="d-flex"
                     v-if="item.status === PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS || item.status === PAYMENTS_STATUSES.OUTPUT_TRANSACTION_STATUS">
                  <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>

                  <template v-if="item.status === 'output'">
                    <template v-if="item.balance_output_id">FR - {{item.balance_output_id}}</template><template v-else>SP - {{item.id}}</template>
                  </template>
                  <template v-else-if="item.status === 'hearty_deal'">
                    HS - {{item.hearty_deal_id}}
                  </template>
                  <template v-else>
                    {{$t('common_Paid.localization_value.value')}}
                  </template>
                </div>
                <div class="d-flex"
                     v-if="item.status === PAYMENTS_STATUSES.CANCELED_TRANSACTION_STATUS ||
                          item.status === PAYMENTS_STATUSES.ERROR_STATUS">
                  <StatusIcoBtn :type="'rejected'" class="mr-2 t-0"/>
                  {{$t('common_Rejected.localization_value.value')}}
                </div>
                <div class="d-flex"
                     v-if="item.status === PAYMENTS_STATUSES.ON_HOLD_STATUS">
                  <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
                  {{$t('common_statusNew.localization_value.value')}}
                </div>
              </div>
            </td>
            <td>
              {{item.user_amount}}
            </td>
            <td width="100%">
            </td>
            <td v-if="isAdmin">
              <div class="table-right table-manage-list">

                <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                               :key="$store.getters.getGlobalUpdateManagerButton"
                               class="w100"
                >
                  <template slot="item">
                    <LinkButton
                        :value="$t('common_delete.localization_value.value')"
                        :type="'delete'"
                        @click.native="removeItem(item.id)"
                    />
                  </template>
                </ManagerButton>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getCusPayments"
                 :key="index"
            >
              <CusPaymentsTableOtherMobile
                      :item="item"
                      :userTabs="userTabs"
                      @showTransactionInfo="showTransactionInfo(item.id)"
                      @removeItem="removeItem(item.id)"
              />
            </div>
          </div>
        </div>

          <div class="table-bottom-btn" >
            <div class="table-bottom-btn__inner">
              <ShowMore
                      v-if="$store.getters.getCusPaymentsCommonList.next_page_url !== null && $store.getters.getCusPayments.length > 0"
                      @click.native="$emit('showMore')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNextCusPaymentsPage}"
                      :count="$store.getters.getCusPaymentsCommonList.total - $store.getters.getCusPaymentsForPage * $store.getters.getCusPaymentsCommonList.current_page < $store.getters.getCusPaymentsForPage ?
                  $store.getters.getCusPaymentsCommonList.total - $store.getters.getCusPaymentsForPage * $store.getters.getCusPaymentsCommonList.current_page:
                  $store.getters.getCusPaymentsForPage"
              />
              <ExportBtn
                  v-if="!userTabs.balanceOutput.active"
                  @downloadFiles="type => $emit('downloadFiles', type)"
                      class="table-bottom-btn__right"/>
            </div>
          </div>
      </div>
    </template>

    <template v-if="$store.getters.getCusPaymentsLoading === false && $store.getters.getCusPayments.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>


    <!--<TransactionInfo-->
            <!--@close="closeTransactionInfo"-->
            <!--v-if="isModalTransactionInfo"-->
    <!--/>-->

    <InfoIDPopup
            v-if="isModalInfoIDPopup"
            @close="isModalInfoIDPopup = false"
            :userTabs="userTabs"
    />

    <!--<WhatOrderCreate-->
            <!--@close="closeWhatOrderCreatePopup"-->
            <!--v-if="isModalWhatOrderCreatePopup"-->
    <!--/>-->

    <!--<ChooseExpressOrderPopup-->
            <!--@close="closeChooseExpressOrder"-->
            <!--v-if="isModalChooseExpressOrder"-->
    <!--/>-->


  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  // import BelposhtaBtn from "../../../../../../coreComponents/BelposhtaBtn/BelposhtaBtn";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  // import InputEditField from "../../../../../../UI/inputs/InputEditField/InputEditField";
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  // import TransactionInfo from "../../../../popups/TransactionInfo/TransactionInfo.vue";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import CusPaymentsTableOtherMobile from "./CusPaymentsTableOtherMobile/CusPaymentsTableOtherMobile";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import InfoIDPopup from "../../../../popups/InfoIDPopup/InfoIDPopup";
  import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import PerPageSelect from "../../../../../../coreComponents/PerPageSelect/PerPageSelect";
  import Pagination from "../../../../../../coreComponents/Pagination/Pagination";
  import TooltipTextHelper from "@/components/coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "CusPaymentsTableOther",
    components: {
      TooltipTextHelper,
      Pagination,
      PerPageSelect,
      TableUserColumn,
      ValueHelper,
      InfoIDPopup,
      NoResult,
      CusPaymentsTableOtherMobile,
      StatusIcoBtn,
      ShowMore,
      ExportBtn,
      ManagerButton,
      // BelposhtaBtn,
      LinkButton,
      // InputEditField,
      // TransactionInfo,
    },

    mixins: [mixinDetictingMobile],

    props: [
      'userTabs',
      'activeTab',
      'countFilterParams',
    ],

    data() {
      return {
        show1: false,
        show2: false,
        PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,

        isModalInfoIDPopup: false,

        items: [
          {
            item: 'empty',
            show: false
          },
          {
            item: 'empty',
            show: false
          },
        ]
      }
    },


    mounted() {

    },

    methods: {
      showTransactionInfo(id) {
        this.$store.dispatch('getCusPayments', id).then(() => {
          this.isModalInfoIDPopup = true
        })
      },

      removeItem(id){
        let text = {
          title: 'cuspayments_deleteTransaction',
          txt: 'cuspayments_deleteTransactionText',
          yes: 'common_yes',
          no: 'common_no'
        }

        let confirm = () => {

          this.$store.dispatch('deleteResidualTransaction', id).then((response) => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                this.openNotify('success', 'common_notificationRecordDeleted')
                this.$emit('close')
                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    },
  }
</script>

<style scoped lang="scss">
  .import-orders-table table td:last-child,
  .import-orders-table table th:last-child {
    width: 270px;
  }

  @media (max-width: 1600px) {
    .table-right__ico {
      margin-left: 10px;
    }
  }

  .comment{
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-card__item-btn-i:first-child{
    padding-left: 5px;
  }

  .small-table{
    th, td{
      white-space: nowrap;
    }
    td:last-child{
      text-align: right;
      padding-right: 15px;
    }
  }
</style>
