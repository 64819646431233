<template>
  <div class="fragment">
    <CusPaymentsHead
            :showFilter="showFilter"
            :userTabs="userTabs"
            :countFilterParams="countFilterParams"
            @toggleFilter="toggleFilter"
            @changeTab="changeTab"
    />
    <div class="table-filter-wrap">

      <CusPaymentsFilter
              :showFilter="showFilter"
              :filterGetParams="filterGetParams"
              :typeShops="typeShops"
              :userTabs="userTabs"
              @close="closeFilter"
              @changeFilter="changeFilter"
              @resetFilter="$emit('resetFilter')"
      />

<!--              v-if="!userTabs.other.active && !userTabs.balanceOutput.active && !userTabs.heartysan.active"-->
      <CusPaymentsTable
              v-if="!userTabs.other.active && !userTabs.balanceOutput.active"
              :userTabs="userTabs"
              :activeTab="activeTab"
              :countFilterParams="countFilterParams"
              @resetFilter="$emit('resetFilter')"
              @changeTrackingNumber="changeTrackingNumber"
              @downloadFiles="type => $emit('downloadFiles', type)"
              @showMore="$emit('showMore')"
              @paginate="(page) => {$emit('paginate', page)}"
              @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />

      <CusPaymentsTableOther
              v-else
              :userTabs="userTabs"
              :activeTab="activeTab"
              :countFilterParams="countFilterParams"
              @resetFilter="$emit('resetFilter')"
              @changeTrackingNumber="changeTrackingNumber"
              @downloadFiles="type => $emit('downloadFiles', type)"
              @showMore="$emit('showMore')"
              @paginate="(page) => {$emit('paginate', page)}"
              @changePerPage="(perPage) => {$emit('changePerPage', perPage)}"
      />

    </div>

  </div>
</template>

<script>
  import CusPaymentsTable from "./CusPaymentsTable/CusPaymentsTable";
  import CusPaymentsFilter from "./CusPaymentsFilter/CusPaymentsFilter";
  import CusPaymentsHead from "./CusPaymentsHead/CusPaymentsHead";
  import CusPaymentsTableOther from "./CusPaymentsTableOther/CusPaymentsTableOther";

  export default {
    name: "CusPaymentsTableAllUser",

    components: {
      CusPaymentsHead,
      CusPaymentsFilter,
      CusPaymentsTable,
      CusPaymentsTableOther,
    },

    props: ['userTabs', 'filterGetParams', 'typeShops', 'countFilterParams'],

    data(){
      return {
        showContent: false,
        showFilter: false,
        filterId: '',
        filterName: '',

        activeTab: 1,

      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      changeTrackingNumber(data) {
        this.$emit('changeTrackingNumber', data)
      },
    }

  }
</script>

<style lang="scss">

</style>
