import {CUSTOMERS_PAYMENTS_TRACKING_NUMBER_STATE, PAYMENT_TYPE_NAME} from "../../staticData/staticVariables";


export const trackingNumberFunc = {
  methods: {
    checkChangeTrackingNumber(funcName, array, property, successName, id, {changedProperty, newValue}) {
      let flag = 0
      return new Promise((resolve) => {
        let interval = setInterval(() => {

          this.$store.dispatch(funcName, id).then((response) => {
            let responseItem = this.getRespData(response)
            if(responseItem[property] === successName) {

              let item = this._.find(array, {id: id})

              item[property] = successName

              if(responseItem[changedProperty] !== newValue){
                this.openNotify('error', 'common_notificationErrorChangeTrackNum')
              } else {
                item[changedProperty] = newValue
              }

              clearInterval(interval)
              resolve('complete')
            }
          })

          if(flag === 5){
            clearInterval(interval)
          }

          ++flag
        }, 5000)
      })
    },

    changeAuthorizeTrackNumber(data, id, ref, item) {
      this.$store.dispatch('updateAuthorizeTrackingNumber',{id: id, data: data}).then(response => {
        if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            document.getElementsByClassName('tooltip__close')[0].click()
            item.tracking_number = data.tracking_number
            this.openNotify('success', 'common_notificationRecordChanged')
            if(!this.isMobileFunc()) {
              this.$refs.closePopover[0].click()
            } else {
              ref.click()
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    changeTrackingNumber({value, id, ref}, transaction_id, index, item){

      let data = {
        tracking_number: value,
        transaction_id: ""+transaction_id,
      }

      if(item.tracking_number === value) {
        document.getElementsByClassName('tooltip__close')[0].click()
        this.openNotify('success', 'common_notificationRecordChanged')
        return
      }

      if(item['payment_system'] === PAYMENT_TYPE_NAME.authorize){
        this.changeAuthorizeTrackNumber(data, id, ref, item)
        return
      }

      if(value === ''){
        this.deleteTrackingNumber({value: value, id: id, ref: ref,}, transaction_id, index)
        return
      }

      this.$store.dispatch('updateCusPaymentsTrackingNumber', data).then((response) => {
        this.trackingNumberResponseProcessing({response: response, value: value, id: id, ref: ref})
      })
    },

    deleteTrackingNumber({value, id, ref}, transaction_id, index){

      let data = {
        tracking_number: this.$store.getters.getCusPayments[index].tracking_number,
        transaction_id: ""+transaction_id,
      }

      this.$store.dispatch('deleteCusPaymentsTrackingNumber', data).then((response) => {
        this.trackingNumberResponseProcessing({response: response, value: value, id: id, ref: ref})
      })
    },

    createTrackingNumber({value, id, ref}, transaction_id, item){
      let data = {
        tracking_number: value,
        transaction_id: ""+transaction_id,
      }

      if(item['payment_system'] === PAYMENT_TYPE_NAME.authorize){
        this.changeAuthorizeTrackNumber(data, id, ref, item)
        return
      }

      this.$store.dispatch('createCusPaymentsTrackingNumber', data).then((response) => {
        this.trackingNumberResponseProcessing({response: response, value: value, id: id, ref: ref})
      })
    },

    trackingNumberResponseProcessing({response, value, id, ref}) {
      if(this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE){

        let orders = this.$store.getters.getCusPayments
        orders.map((item, index) => {
          if(item.id === id){
            orders[index].tracking_number_state = CUSTOMERS_PAYMENTS_TRACKING_NUMBER_STATE.IN_PROGRESS
          }
        })

        document.getElementsByClassName('tooltip__close')[0].click()
        console.log(ref);

        this.openNotify('success', 'common_notificationRecordInProcess')
        this.checkChangeTrackingNumber(
          'getCusPayments',
          orders,
          'tracking_number_state',
          null,
          id,
          {
            changedProperty: 'tracking_number',
            newValue: value
          }
          )

      } else if(response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        let errors = response.response.data.errors;

        setTimeout(() => {
          if(Object.hasOwnProperty.call(errors, 'tracking_number')){
            this.openNotify('error', {txtServer: errors['tracking_number'][0]})
          } else if(Object.hasOwnProperty.call(errors, 'transaction_id')) {
            this.openNotify('error', {txtServer: errors['transaction_id'][0]})
          }
        }, 200)
      }
    },

    changeTrackingNumberImportOrders({value, id, ref}) {
      let data = {
        tracking_number: value
      }
      this.$store.dispatch('updateImportOrdersTrackingNumber', {id: id, data: data}).then((response) => {
        if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

          let orders = this.$store.getters.getImportOrders
          let indexOrder
          orders.map((item, index) => {
            if (item.id === id) {
              orders[index].tracking_number = value
              // orders[index].tracking_sent = 1
              orders[index].shipped_at = response.data.data.shipped_at
              indexOrder = index
            }
          })

          document.getElementsByClassName('tooltip__close')[0].click()
          console.log(ref);
          // if (!this.isMobileFunc()) {
          //   this.$refs.closePopover[0].click()
          // } else {
          //   ref.click()
          // }

          this.openNotify('success', 'common_notificationRecordChanged')
          this.checkChangeTrackingNumber('getImportOrders', orders, 'tracking_sent', 0, id).then(() => {
            orders[indexOrder].tracking_number = value.length > 0 ? value : 0
          })

        } else if (response.response.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
          let errors = response.response.data.errors;

          setTimeout(() => {
            this.openNotify('error', {txtServer: errors['tracking_number'][0]})
          }, 200)

        } else {
          setTimeout(() => {
            this.openNotify('error', 'common_notificationError')
          }, 200)
        }


      })
    },
  }
}
